.meeting a {
  text-decoration: underline;
}

.meeting ul {
  list-style:circle;
}

.panel, .panel-heading {
  background-color: white;
}

.table thead td, .table thead th {
  border: 1px solid black;
  border-width: 0 0 1px;
  padding-bottom: 15px;
}

.divide {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 10px;
}