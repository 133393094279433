
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  background:#b0b0b0;
	background: linear-gradient(#ffffff,#e2e0e0);
}

.section {
  padding: 1.5rem;
}

.divider {
  border-bottom: 1px solid #e2e0e0;
  margin-bottom: 15px;
}

#tsml-ui .meeting.online .map {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.25;
  position: absolute;
  right: 0;
  top: 0;
  background-image: url(https://images.unsplash.com/photo-1588196749597-9ff075ee6b5b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1440&ixid=MnwxfDB8MXxhbGx8fHx8fHx8fHwxNjIyMTIzODkw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1920);

}

#tsml-ui .meeting.in-person.online .map {
  background-image: none;
  opacity: 1;

}

#tsml-ui .meeting.online .map {
  position: relative;
}

.recurring {
  overflow: hidden;
  position: relative;
}
.ribbon {
  margin:0;
  padding: 0;
  background: #006d51;
  color: white;
  padding: .5em 0;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
}
.ribbon:before,
.ribbon:after{
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px;
  width:100%;
  height: 100%;
  background: #006d51;
}
.ribbon::before{
  right: 100%;
}

.buttons .ca-bg {
  margin-top: 15px;
  background: #006d51;
}